$helpful: 'Hjelpsom (';
$nothelpful: 'Ikke hjelpsom (';
$filter: 'FILTER :';
$out-of: ' av 5';

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          width: 64%;
          .pr-multiselect-button-alder {
            &:before {
              content: $filter;
            }
          }
        }
        .pr-multiselect {
          .pr-multiselect-button {
            min-width: 100px;
            padding: 8px 20px 8px 6px;
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: $helpful;
                &:after {
                  content: ')';
                }
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: $nothelpful;
                &:after {
                  content: ')';
                }
              }
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      &-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet {
            .pr-snippet-rating-decimal {
              &:after {
                content: $out-of;
              }
            }
          }
        }
      }
    }
  }
}

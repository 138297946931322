//fonts

@mixin OptimaEL() {
  font-family: 'Optima Display Roman';
}

@mixin OptimaLT() {
  font-family: 'Optima Display Roman';
}

@mixin AkzidGro() {
  font-family: 'AkzidenzGrotesk W1G';
}

@charset "UTF-8";

.elb-privacy-policy {
  h1,
  h2,
  h3,
  h4,
  h5 {
    @include OptimaEL();
  }
}

.ff-quiz__question {
  li {
    word-wrap: break-word;
    a h3 {
      @include OptimaEL();
    }
  }
}

.ff-results__matches {
  .ff-results__regimen-step .ff-results__headline {
    @include OptimaEL();
  }
}

.customer-service {
  .basic-textarea-v1 {
    h2,
    h3 {
      @include OptimaEL();
    }
  }
  h1.sidebar-page__content-title {
    @include OptimaEL();
  }
}

.careers-landing {
  h1.headline--primary,
  .careers-quote {
    @include OptimaEL();
  }
}

.customer-service {
  h2#max-purchase {
    @include OptimaEL();
  }
}

.formatter-estee-edit__content {
  .estee-edit__header {
    h1,
    h2,
    h4 {
      @include OptimaEL();
    }
  }
  blockquote {
    @include OptimaEL();
  }
  .ee-disco-more__headline {
    @include OptimaEL();
  }
}

.email_popover {
  .email_popover__content {
    h4,
    p,
    input {
      @include OptimaEL();
    }
  }
  .email_popover__disclaimer {
    p,
    a {
      @include OptimaEL();
    }
  }
  .email_popover__shop_now_link {
    @include OptimaEL();
  }
}

h1.sidebar-page__content-title {
  @include OptimaEL();
}

#sidebar {
  .links-panel__title {
    @include AkzidGro();
  }
}

#signin {
  #signin-block {
    h3 {
      @include AkzidGro();
    }
  }
}

.ee-mb-tile {
  @include OptimaEL();
  h4 {
    @include OptimaEL();
  }
}

.headline--section,
.samples-panel__title,
.careers-quote,
.ee-mb-tile__text h4,
.headline--secondary,
.headline--medium {
  @include OptimaEL();
}

.registration-page {
  .registration-page__content {
    #registration h3.headline--section {
      @include OptimaEL();
      font-size: 25px;
    }
  }
}

.address_form_container {
  #address .address h3 {
    @include OptimaEL();
  }
}

.spp-product__how-to-use {
  .spp_howtouse__header {
    .headline--secondary {
      @include OptimaLT();
    }
  }
}

.eye-care-elc-mpp {
  h2.mpp__header {
    @include AkzidGro();
  }
}

// loyalty signup
.loyalty-offer-overlay {
  #cboxContent {
    background-color: transparent;
  }
  #cboxClose {
    background-image: url('/media/images/pro_active_popup/transparent_close_x.png') !important;
    background-size: 28px 28px;
    background-position: right center;
    background-size: contain;
    top: 0 !important;
    #{$rdirection}: 0 !important;
  }
}

.device-mobile {
  #colorbox.loyalty-offer-overlay {
    background: $color-navy;
    opacity: 0.9 !important;
    #cboxContent {
      @include swap_direction(padding, 0 22px 0 22px);
    }
    #cboxClose {
      position: fixed !important;
      background-position: top center !important;
      background-size: 33px !important;
      height: 33px !important;
      width: 33px !important;
      top: 25px !important;
      #{$rdirection}: 25px !important;
      @include swap_direction(padding, 0 !important);
      // &:hover {
      //   background-position: top center;
      //   height: 28px;
      //   width: 60px;
      // }
    }
  }
}

.loyalty_popover {
  color: $color-white;
  @include swap_direction(padding, 46px 0 10px 0);
  margin-top: 25px;
  overflow: hidden;
  @media #{$medium-up} {
    margin-top: 0;
    @include swap_direction(padding, 0 0 10px 80px);
  }
  a {
    color: $color-periwinkle-blue;
    text-decoration: underline;
  }
  &.valid_used {
    text-align: $ldirection;
    @include swap_direction(padding, 62px 0 10px 0);
    @media #{$medium-up} {
      text-align: center;
      @include swap_direction(padding, 0 0 10px 0);
    }
  }
  &.valid_unused {
    @media #{$small-only} {
      @include swap_direction(padding, 52px 0 10px 0);
    }
  }
  &.invalid_unused,
  &.invalid_used {
    @media #{$small-only} {
      @include swap_direction(padding, 40px 0 10px 0);
    }
  }
  #loyalty_popover__checkbox ~ .loyalty_popover__checkbox-label::before,
  #loyalty_popover__checkbox ~ .loyalty_popover__checkbox-label::after {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    @include swap_direction(border, medium none);
  }
  .loyalty_popover__checkbox-container .loyalty_popover__checkbox-label {
    padding-#{$ldirection}: 0;
  }
}

.loyalty_popover__header {
  font-size: 44px;
  line-height: 48px;
  letter-spacing: -0.02em;
  @media #{$medium-up} {
    font-size: 74px;
    line-height: 82px;
    letter-spacing: -0.05em;
  }
  .valid_unused &,
  .invalid_unused &,
  .valid_used &,
  .invalid_used & {
    font-size: 40px;
    line-height: 42px;
    @media #{$medium-up} {
      font-size: 74px;
      line-height: 80px;
    }
  }
}

.loyalty_popover__margin {
  @media #{$medium-up} {
    margin-#{$ldirection}: 8px;
  }
}

.loyalty_popover__sub-header {
  font-size: 21px;
  letter-spacing: -0.02em;
  @media #{$medium-up} {
    font-size: 38px;
    line-height: 42px;
    letter-spacing: -0.04em;
  }
  .valid_unused &,
  .valid_used &,
  .invalid_unused &,
  .invalid_used & {
    font-family: $akzidenz;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.12em;
    @media #{$medium-up} {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.15em;
    }
  }
  .valid_used & {
    font-weight: bold;
    margin-top: 20px;
    @media #{$medium-up} {
      margin-top: 6px;
    }
  }
  .invalid_used & {
    @media #{$small-only} {
      margin-top: 20px;
    }
  }
}

.loyalty_popover__promo-header {
  font-family: $optimalight;
  margin-top: 20px;
  letter-spacing: -0.02em;
  font-size: 25px;
  line-height: 27px;
  @media #{$medium-up} {
    font-size: 40px;
    line-height: 42px;
    margin-top: 15px;
  }
}

.loyalty_popover__promo-text {
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  @media #{$medium-up} {
    font-size: 15px;
    line-height: 24px;
  }
}

.loyalty_popover__promo,
.loyalty_popover__status {
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  @include swap_direction(margin, 20px 0 25px 0);
  @media #{$medium-up} {
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.15em;
    @include swap_direction(margin, 17px 0 22px 0);
  }
  a {
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.15em;
    @if $loyalty-popup-cr23update {
      display: block;
      color: $color-navy;
    } @else {
      display: inline-block;
      @media #{$medium-up} {
        margin-#{$ldirection}: 10px;
      }
    }
  }
  .valid_unused &,
  .invalid_unused & {
    @include swap_direction(margin, 20px 0 25px 0);
    letter-spacing: 0.12em;
    @media #{$medium-up} {
      @include swap_direction(margin, 15px 0 20px 0);
      letter-spacing: 0.15em;
    }
  }
  .valid_used & {
    margin-top: 0;
  }
  .invalid_unused & {
    @include swap_direction(margin, 0 0 15px 0);
    letter-spacing: 0.15em;
  }
}

.loyalty_popover__input {
  .form-text,
  .form-password,
  input[type='email'],
  input[type='password'],
  input[type='text'] {
    // .form-text
    width: 100%;
    @include box-shadow(none);
    color: $color-black;
    background-color: $color-white;
    @media #{$medium-up} {
      color: $color-white;
      background-color: transparent;
      @include swap_direction(border, 1px solid $color-white);
      width: 268px;
      float: $ldirection;
      margin-#{$rdirection}: 9px;
    }
    @include input-placeholder {
      color: $color-black;
      @media #{$medium-up} {
        color: $color-white;
        background-color: transparent;
      }
    }
  }
  .form-checkbox {
  }
  .form-submit {
    background: $color-navy;
    width: 100%;
    margin-top: 15px;
    line-height: 42px;
    height: 42px;
    @media #{$medium-up} {
      float: $ldirection;
      width: 96px;
      margin-top: 0;
      background: transparent;
      padding-#{$ldirection}: 17px;
      padding-#{$rdirection}: 17px;
      line-height: normal;
      height: 33px;
    }
    .invalid_unused &,
    .invalid_used & {
      @media #{$small-only} {
        margin-top: 20px;
      }
    }
  }
}

.loyalty_popover__password {
  .form-password {
    width: 290px;
    margin-#{$rdirection}: 10px;
  }
}

.loyalty_popover__firstname {
  input[type='text'] {
    width: 100%;
    @include swap_direction(margin, 0 0 20px 0);
    @media #{$medium-up} {
      width: 268px;
    }
  }
}

.loyalty_popover__checkbox {
  &-container {
    margin-top: 18px;
    width: 100%;
    @media #{$medium-up} {
      margin-top: 26px;
      width: 360px;
    }
  }
  &-label {
    text-transform: none;
    letter-spacing: 0.05em;
    @media #{$medium-up} {
      font-size: 13px;
      letter-spacing: 0.12em;
    }
  }
}

.loyalty_popover__submit-container {
  @include clearfix;
}

.loyalty_popover__link {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.15em;
  font-weight: bold;
  &-container {
    margin-top: 24px;
    .invalid_unused &,
    .invalid_used & {
      @media #{$small-only} {
        margin-top: 26px;
      }
    }
  }
}

.loyalty_popover__terms {
  font-size: 14px;
  margin-top: 24px;
  letter-spacing: 0.05em;
  @media #{$medium-up} {
    font-size: 12px;
    margin-top: 23px;
    letter-spacing: 0.1em;
  }
  .invalid_unused &,
  .invalid_used & {
    margin-top: 20px;
  }
}

.loyalty_popover__signup {
  @include swap_direction(padding, 0 0 75px 0);
  overflow-y: auto;
  @media #{$medium-up} {
    @include swap_direction(padding, 0 10px 0 0);
  }
}

.loyalty_popover__welcome {
  @include swap_direction(padding, 0 0 20px 0);
  @media #{$medium-up} {
    @include swap_direction(padding, 0 10px 0 0);
  }
  &-option {
  }
}

.loyalty_popover__buttons,
.loyalty_popover__button {
  margin-top: 35px;
  .button {
    color: $color-periwinkle-blue;
    border-color: $color-periwinkle-blue;
    text-decoration: none;
  }
  .valid_unused & {
    margin-top: 20px;
  }
  .valid_used & {
    text-align: center;
    margin-top: 20px;
  }
}

.loyalty_popover.valid_unused {
  .button {
    width: 100%;
    @media #{$medium-up} {
      width: 270px;
    }
  }
}

.loyalty_popover.valid_used {
  .button {
    width: 100%;
    @media #{$medium-up} {
      width: 250px;
    }
    &:last-child {
      @include swap_direction(margin, 15px 0 0 0);
      @media #{$medium-up} {
        @include swap_direction(margin, 0 0 0 30px);
      }
    }
  }
}

.loyalty_popover__offer-note {
  font-size: 14px;
  letter-spacing: 0.05em;
  @media #{$medium-up} {
    margin-top: 28px;
    font-size: 12px;
    letter-spacing: 0.1em;
  }
  .invalid_unused &,
  .invalid_used & {
    margin-top: 23px;
    @media #{$medium-up} {
      margin-top: 24px;
    }
  }
  .valid_unused & {
    @media #{$small-only} {
      margin-top: 23px;
    }
  }
}

.loyalty_popover__password {
  &-container {
  }
}

.loyalty_popover__choose {
  @include swap_direction(margin, 17px 0 20px 0);
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  @media #{$medium-up} {
    @include swap_direction(margin, 14px 0 22px 0);
  }
}

.field-mobile-template {
  .loyalty_multi_image_tout {
    background: url('/media/export/cms_2.0/lojalitet/mobil/loyalty_marketing_bg_slider.png') no-repeat scroll 0 0 / 100%
      auto;
    position: relative;
    .loyalty_multi_image_tout_left {
      position: absolute;
      text-align: center;
      top: 5%;
      width: 100%;
      img {
        width: 90%;
      }
    }
    .loyalty_multi_image_tout_right {
      text-align: center;
      padding-top: 35%;
      width: 100%;
      img {
        width: 70%;
      }
    }
  }
  .loyalty_popover {
    &__submit-container .form-text {
      color: $color-navy;
    }
    &__checkbox-label,
    &__terms {
      font-size: inherit;
      @media #{$medium-up} {
        padding-#{$rdirection}: 32px;
      }
    }
  }
  @media #{$iphone6-portrait},
    #{$iphone6-landscape} {
    .loyalty_market {
      .loyalty_market__ {
        &excited__benefits {
          font-size: 13px;
          font-weight: bold;
          letter-spacing: 0.05em;
          margin-top: 8px;
          text-transform: uppercase;
        }
        &points__list {
          font-size: inherit;
          letter-spacing: 0.05em;
        }
      }
    }
  }
}

.device-mobile {
  .loyalty_popover {
    &__submit-container .form-text {
      color: $color-navy;
    }
    &__checkbox-label,
    &__terms {
      font-size: inherit;
      //padding-#{$rdirection}: 32px;
    }
  }
}

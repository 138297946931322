#loyalty__panel__benefits {
  @extend %loyalty-panel;
  @media #{$medium-up} {
    display: block;
    padding-bottom: 0;
    &.is-guest,
    #loyalty__page__about & {
      float: $ldirection;
      width: 50%;
    }
  }
  .loyalty__panel__benefits__container {
    display: none;
    @media #{$medium-up} {
      display: block;
    }
  }
  .loyalty__panel__benefits__title {
    @extend %loyalty-panel-title;
  }
  .loyalty__panel__benefits__title__link {
    display: block;
    float: $rdirection;
    font-family: $akzidenz;
    font-size: 12px;
    line-height: 38px;
    text-transform: uppercase;
    text-decoration: underline;
  }
  .loyalty__panel__benefits__container {
    margin-top: 36px;
  }
  .loyalty__panel__benefits__tiers {
    @extend %loyalty-clear;
  }
  .loyalty__panel__benefits__tier {
    position: relative;
    display: block;
    float: $ldirection;
    width: 33.3333333333333%;
    text-align: center;
    @include opacity(0.3);
    &.previous_tier {
      opacity: 1;
      &_benefits {
        opacity: 1;
      }
      .loyalty__panel__benefits__tier-header {
        background: $color-white;
      }
    }
    &.next_tier {
      .loyalty__panel__benefits__tier-header {
        background: $color-white;
      }
    }
  }
  .loyalty__current__tier_1 .loyalty__panel__benefits__tier--1,
  .loyalty__current__tier_2 .loyalty__panel__benefits__tier--2,
  .loyalty__current__tier_3 .loyalty__panel__benefits__tier--3 {
    @include opacity(1);
  }
  .loyalty__panel__benefits__tier-header.default_tier {
    background: url('/media/images/account/lojalitet/benefit_arrow.gif') no-repeat scroll center bottom;
  }
  .loyalty__panel__benefits__tier-header.on_hover {
    background: url('/media/images/account/lojalitet/benefit_arrow.gif') no-repeat scroll center bottom;
  }
  .loyalty__panel__benefits__tier-header {
    background: $color-white;
    @include swap_direction(padding, 10px 0px 22px 0px);
  }
  .loyalty__panel__benefits__tier-level {
    @extend %loyalty-title;
  }
  .loyalty__panel__benefits__tier-points {
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
  .loyalty__panel__benefits__description {
    @include min-height(400px);
    position: relative;
    @include swap_direction(padding, 30px 10px 40px 10px);
    background: #dadadf;
  }
  .loyalty__panel__benefits__tier:hover {
    opacity: 1;
  }
  .loyalty__panel__benefits__description-list {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    li {
      //line-height: 32px;
      @include swap_direction(padding, 9px 0);
      &.loyalty__panel__benefits__first {
        font-size: 12px;
        letter-spacing: 0.1em;
        line-height: 1.8em;
        color: $color-navy;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
  li.loyalty__panel__benefits__unlock {
    visibility: hidden;
    font-size: 15px;
    line-height: 24px;
    @include swap_direction(padding, 32px 20px 0 20px);
    line-height: normal;
  }
  .loyalty__panel__benefits__description-list__overlay {
    position: absolute;
    width: 100%;
    bottom: 0;
    #{$ldirection}: 0;
    @include swap_direction(padding, 0 20px 40px 20px);
    line-height: normal;
    li {
      display: none;
    }
    li.loyalty__panel__benefits__unlock {
      display: block;
      visibility: visible;
    }
  }
  .elist_terms_condition {
    .other_limits {
      margin-bottom: 0;
    }
    @media #{$medium-up} {
      margin-top: 26px;
    }
    p {
      font-size: 12px;
      color: $color-navy;
      line-height: 18px;
      .loyalty_panel_elist_terms_condition_header {
        font-weight: bold;
        display: block;
        text-decoration: underline;
        margin-bottom: 10px;
      }
    }
  }
}

#loyalty__content #loyalty__panel__benefits .loyalty__panel__benefits__tier.next_tier {
  opacity: 0.3;
}

#loyalty__panel__benefits {
  padding-bottom: 0;
  @media #{$medium-up} {
    padding-bottom: 10px;
  }
}

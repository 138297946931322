.refer__friend__section {
  display: inline-block;
  @include swap_direction(padding, 10px 25px);
  @media #{$medium-up} {
    padding: 0;
  }
  h3 {
    padding-bottom: 16px;
    margin-bottom: 10px;
    text-align: $ldirection;
    @media #{$medium-up} {
      border-bottom: 1px solid $color-light-gray;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  p {
    line-height: 20px;
  }
  h4 {
    font-weight: bold;
    @media #{$medium-up} {
      font-weight: normal;
    }
  }
  .refer__details__panel {
    h4 {
      margin-bottom: 20px;
      @media #{$medium-up} {
        margin-bottom: 12px;
      }
    }
    p {
      @include swap_direction(margin, 0 0 20px);
      @media #{$medium-up} {
        margin: 0;
        line-height: 22px;
      }
      &.refer_desc_panel {
        padding-top: 5px;
        @media #{$medium-up} {
          margin-top: 16px;
          padding-top: 0;
          width: 74%;
        }
      }
      strong {
        text-transform: uppercase;
        font-size: 13px;
      }
    }
  }
  .e-list_signed-left {
    &#refer__list__panel {
      @media #{$medium-up} {
        width: 100%;
      }
    }
    @media #{$medium-up} {
      .refer__share__link__panel {
        p {
          margin-top: 8px;
        }
        margin-top: 20px;
      }
    }
    .loyalty__panel__earn__share {
      input[type='text'] {
        width: 100%;
        color: $color-black;
        border-color: $color-black;
      }
      button {
        width: 100%;
        border: 1px solid $color-navy;
        padding: 14px 0;
        margin: 15px 0 0;
      }
      @media #{$medium-up} {
        input[type='text'] {
          box-shadow: none;
          width: 68%;
        }
        button {
          @include swap_direction(padding, 8px 8px 7px);
          padding: 8px 8px 7px;
          width: 23%;
          @include swap_direction(margin, 0 0 0 -5px);
        }
      }
    }
    .refer__points__panel {
      display: inline-block;
      width: 100%;
      @media #{$medium-up} {
        h4 {
          margin-bottom: 20px;
        }
        @include swap_direction(margin, 30px 0 10px);
      }
      ul {
        width: 100%;
        display: inline-block;
        @include swap_direction(margin, 20px 0 0);
        @media #{$medium-up} {
          @include swap_direction(margin, 0 0 5px);
        }
        li {
          float: $ldirection;
          &.refer_name {
            @media #{$medium-up} {
              width: 30%;
            }
            width: 32%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-right: 15px;
          }
          &.refer_status {
            @media #{$medium-up} {
              width: 30%;
            }
            width: 38%;
            text-transform: uppercase;
            font-size: 14px;
          }
          &.refer_points {
            @media #{$medium-up} {
              width: 40%;
            }
            color: $color-red;
            width: 30%;
          }
        }
      }
    }
    .social-share-icons {
      @include swap_direction(margin, 25px 0 35px);
      text-align: center;
      @media #{$medium-up} {
        @include swap_direction(margin, 25px 0 0);
        text-align: $ldirection;
      }
    }
    .loyalty__panel__earn__footer {
      a {
        border-bottom: 1px solid $color-navy;
        line-height: 1;
        display: inline-block;
      }
    }
    .social-share-icons {
      .social-share__link {
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        &.email {
          background-size: auto auto;
          background-position: 0 -9036px;
          height: 16px;
          width: 22px;
        }
        &.facebook {
          background-size: auto auto;
          background-position: 0 -9227px;
          height: 19px;
          width: 11px;
        }
        &.twitter {
          background-size: auto auto;
          background-position: 0 -9612px;
          height: 19px;
          width: 22px;
        }
      }
    }
  }
  &.loyalty__panel__earn__share__email {
    position: relative;
    @include swap_direction(padding, 30px 0);
    @media #{$medium-up} {
      @include swap_direction(padding, 42px 10px 30px);
    }
    input[type='text'] {
      width: 100%;
      margin-top: 0;
      box-shadow: none;
      color: $color-black;
      border-color: $color-black;
      @media #{$medium-up} {
        height: 44px;
        line-height: 42px;
        @include swap_direction(padding, 0 10px);
      }
      &.error {
        border: 1px solid $color-red;
        color: $color-red;
      }
    }
    button {
      border: 1px solid $color-navy;
      @include swap_direction(padding, 12px 18px);
      @media #{$medium-up} {
        margin-top: 10px;
      }
      margin-top: 15px;
      margin-right: 18px;
    }
    #preview {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.8px;
      text-decoration: underline;
    }
    .loyalty__panel__earn__share__email__error {
      margin-bottom: 10px;
      color: $color-red;
      @media #{$medium-up} {
        margin: 0;
        top: 24px;
        position: absolute;
        line-height: 16px;
      }
    }
    p {
      &.pc-hidden {
        margin: 10px 0;
      }
    }
    .loyalty_onload_section {
      &.hidden {
        display: none;
      }
      &.raf_privacy_policy {
        margin-top: 10px;
        a {
          text-decoration: underline;
        }
      }
    }
    .loyalty__panel__earn__share__email__success {
      margin-top: 30px;
      a {
        border-bottom: 1px solid $color-black;
      }
    }
  }
  &.loyalty__panel__preview {
    @include swap_direction(margin, 40px auto 0);
    @include swap_direction(padding, 25px 15px);
    display: block;
    @media #{$small-only} {
      width: 230px;
    }
    @media #{$medium-only} {
      width: 320px;
    }
    @media #{$medium-up} {
      @include swap_direction(padding, 35px 30px);
    }
    border: 1px solid $color-black;
    p {
      margin-bottom: 22px;
      font-size: 13px;
      @media #{$medium-up} {
        font-size: 14px;
      }
      letter-spacing: 1.8px;
      span {
        display: block;
        @media #{$medium-down} {
          display: inline;
        }
      }
    }
    .invite-info {
      text-transform: uppercase;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    h4 {
      font-size: 34px;
      text-shadow: 0px -1px 0px $color-black;
    }
    p {
      line-height: 18px;
    }
    .join-section {
      .join-now {
        width: 120px;
        @include swap_direction(padding, 5px 20px);
        margin-top: 0;
        font-size: 12px;
        text-align: center;
        display: inline-block;
        cursor: default;
      }
      .learn-more {
        display: inline-block;
        margin-left: 20px;
        margin-top: 0;
        letter-spacing: 0.8px;
        a {
          cursor: default;
        }
        small {
          position: relative;
          top: 3px;
          font-size: 22px;
        }
      }
    }
    .page-branding {
      position: static;
      height: auto;
      margin: 0;
      float: none;
      padding-top: 15px;
      @media #{$medium-up} {
        margin-top: 18px;
        padding-top: 0;
      }
      .page-branding__logo {
        position: static;
        margin-left: 0;
        cursor: default;
      }
    }
  }
}

.my-feed-loyalty-raf {
  margin-bottom: 25px;
  a {
    text-decoration: underline;
    color: $color-periwinkle-blue;
  }
}

.loyalty_multi_image_tout {
  .loyalty_raf_form {
    padding-left: 25px;
    padding-right: 25px;
    .raf_form {
      #raf_email_id {
        display: block;
        box-shadow: none;
        width: 100%;
        border: 1px solid $color-black;
        margin-bottom: 12px;
        @media #{$medium-up} {
          width: 290px;
          height: 36px;
          float: $ldirection;
          margin-right: 10px;
          margin-bottom: 0;
        }
      }
      input[type='submit'] {
        display: block;
        border: none;
        @include swap_direction(padding, 15px 0);
        width: 100%;
        @media #{$medium-up} {
          width: 100px;
          @include swap_direction(padding, 10px 0);
          float: $ldirection;
        }
      }
    }
    .sub_title {
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 18px;
      font-family: $akzidenz;
      text-align: $ldirection;
      @media #{$medium-up} {
        text-align: $rdirection;
      }
    }
    &.loyalty_multi_image_tout_right {
      text-align: $ldirection;
      padding-bottom: 40px;
      background-image: url(/media/export/cms_2.0/lojalitet/mobil/loyalty_marketing_bg01.jpg);
      background-repeat: no-repeat;
      background-position: right bottom;
      @media #{$medium-up} {
        padding-bottom: 0;
        background-image: none;
        background-position: 0 0;
      }
      .sub_title {
        margin-bottom: 0;
        @media #{$medium-up} {
          text-align: $ldirection;
          margin-top: 10px;
        }
      }
      .raf_signup_text {
        margin-top: 8px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 18px;
        font-family: $akzidenz;
      }
      .raf_signup_form {
        margin-top: 18px;
        margin-bottom: 20px;
        overflow: hidden;
      }
      .raf_terms_condition {
        clear: both;
        p {
          font-size: 12px;
          line-height: 18px;
          a {
            text-decoration: underline;
          }
          span {
            @media #{$medium-up} {
              display: block;
            }
          }
        }
      }
      .raf_learn_more {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        a {
          text-decoration: underline;
        }
      }
      .existing_user {
        .raf_user_message {
          line-height: 14px;
        }
        p {
          &.sub_title {
            line-height: 14px;
          }
          &:last-child {
            font-size: 13px;
            margin: 0;
            text-transform: uppercase;
          }
          a {
            text-decoration: underline;
          }
          span {
            display: block;
            @media #{$medium-up} {
              display: inline;
            }
          }
        }
        h4 {
          margin-top: 13px;
          margin-bottom: 15px;
          span {
            display: block;
            font-size: 13px;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-family: $akzidenz;
          }
        }
      }
      .start_shopping {
        .cta {
          display: block;
          border: none;
          text-align: center;
          @include swap_direction(padding, 15px 25px);
          margin-top: 12px;
          width: 100%;
          @media #{$medium-up} {
            width: 180px;
            @include swap_direction(padding, 10px 22px);
          }
        }
        p {
          font-size: 12px;
        }
      }
      #availed_user {
        h4 {
          margin-top: 15px;
          @media #{$medium-up} {
            margin-top: 5px;
          }
        }
        .sub_title {
          margin-top: 7px;
          @media #{$medium-up} {
            margin-top: 10px;
          }
        }
        .whats_happening {
          @include swap_direction(margin, 0 0 15px);
          text-transform: uppercase;
          font-size: 13px;
          line-height: 20px;
        }
      }
      .loyalty_registration {
        p {
          line-height: 20px;
          span {
            @media #{$medium-up} {
              display: block;
            }
          }
        }
        #username,
        #password {
          display: block;
          box-shadow: none;
          width: 100%;
          height: 45px;
          margin-bottom: 10px;
          border: 1px solid $color-black;
          @media #{$medium-up} {
            width: 290px;
            height: 36px;
          }
        }
        #password {
          float: $ldirection;
          margin-right: 10px;
        }
        input[type='submit'] {
          display: block;
          width: 100%;
          border: none;
          @include swap_direction(padding, 15px 0);
          @media #{$medium-up} {
            @include swap_direction(padding, 10px 0);
            width: 100px;
          }
        }
      }
    }
    .error_messages {
      li {
        text-align: $ldirection;
        font-size: 13px;
        color: $color-red;
        letter-spacing: 0.5px;
        margin-bottom: 5px;
        font-family: $akzidenz;
      }
    }
  }
}
/** Loyalty points history page fix for mobile to match the design for RAF start **/
.pg_wrapper {
  &.loyalty-account-page {
    #loyalty__page__history {
      #loyalty__panel__transactions {
        td {
          @media #{$medium-down} {
            border-top: 1px solid $color-light-gray;
            border-bottom: none;
          }
        }
        tr {
          &.activity_content {
            td {
              @media #{$medium-down} {
                border-top: none;
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
/** Loyalty points history page fix for mobile to match the design for RAF end **/
.estee-e-list-refer-a-friend-esteelaudercom-page {
  .loyalty_mrkt_ldng {
    .loyalty_multi_image_tout {
      .loyalty_multi_image_tout_left {
        margin-top: 131px;
      }
      .loyalty_multi_image_tout_right {
        margin-top: 137px;
      }
    }
    .loyalty_mrkt_ldng_title {
      top: 115px;
    }
  }
  .field-mobile-template {
    .loyalty_multi_image_tout {
      background-image: url(/media/export/cms_2.0/lojalitet/mobil/raf_marketing_slider_bg01.jpg);
    }
    .loyalty_multi_image_tout_left {
      &.loyalty_raf_form {
        text-align: $ldirection;
      }
    }
    #availed_user {
      .start_shopping {
        .offer_valid {
          display: none;
        }
      }
    }
    .loyalty_market {
      .loyalty_market__header {
        display: none;
      }
    }
  }
}
